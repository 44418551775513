import { Box, Button, Center, Flex, Icon, Link } from "@chakra-ui/react";
import { Login } from "./page";
import { FiLogIn } from "react-icons/fi";
import { useTranslation } from "src/hooks/useTranslation";

export default function Page() {
  const t = useTranslation();
  return (
    <Box h={"100%"} w={"100%"}>
      <Flex maxW={"1000px"} h={"100%"} w={"100%"} mx="auto">
        <Center
          w={"50%"}
          _dark={{
            bgGradient: "linear(to-br, #04aa5d, #1e3739 66%)",
          }}
        >
          <img width={"80%"} src={"/logo.png"} alt="" />
        </Center>

        <Center w={"50%"} pos="relative">
          <Box
            w={"80%"}
            maxW={"400px"}
            rounded="xl"
            p={4}
            boxShadow="0 0 5px 0px #0000000f"
            bg="gray.50"
          >
            <Center mb={6} textAlign="center" fontSize={20} fontWeight={600}>
              <Icon as={FiLogIn} ml={-8} w={6} mr={2} />
              <Box>{t("signIn")}</Box>
            </Center>

            <Box minH="230px">
              <Login />
            </Box>

          </Box>
        </Center>
      </Flex>
    </Box>
  );
}
